import React from "react";
import { useRoutes } from "react-router-dom";
import routes from "virtual:generated-pages-react"; // Automatically generated routes

function App() {
  const routing = useRoutes(routes);
  return routing;
}

export default App;
