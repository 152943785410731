import React from "react";
import { Login } from "@ui/sustentus";
import api from "@api/sustentus";
import { useAccountState } from "@state/sustentus"; // Importing state manager

const LoginPage = () => {
  const { signIn } = useAccountState(); // Access signIn from the Recoil state manager

  const handleLogin = async (credentials) => {
    try {
      const response = await api.post("/users/auth/login", credentials);
      const { accessToken, user } = response.data.data;
      await signIn(user.role, user, accessToken);
    } catch (error) {
      alert("Login failed, try again");
      console.error("Login failed:", error);
    }
  };

  return <Login onSubmit={handleLogin} />;
};

LoginPage.displayName = "Login";
export default LoginPage;
