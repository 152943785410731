// src/main.jsx
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {  RecoilProvider } from "@state/sustentus";
import { configureApi } from "@api/sustentus";
import App from "./App.jsx";
import "./index.css";

configureApi({
  apiUrl: import.meta.env.VITE_API_URL,
});

ReactDOM.createRoot(document.getElementById("root")).render(
    <RecoilProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </RecoilProvider>
);
